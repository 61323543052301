import {environment} from "../environments/environment";

export const routes = {
    session: {
        login: () => `${environment.backend.endpoint}/auth/login`,
        logout: () => `${environment.backend.endpoint}/auth/logout`,
        me: () => `${environment.backend.endpoint}/auth/me`,
    },
    structural: {
        tabs: {
            get: () => `${environment.backend.endpoint}/tabs/get`,
        },
    },
    users: {
        index: (page = 1) => `${environment.backend.endpoint}/users-management?page=${page}`, // GET
        create: () => `${environment.backend.endpoint}/users-management`, // POST
        show: (userId: number) => `${environment.backend.endpoint}/users-management/${userId}`, // GET
        edit: (userId: string) => `${environment.backend.endpoint}/users-management/${userId}`, // PUT
        delete: (userId: string) => `${environment.backend.endpoint}/users-management/${userId}`, // DELETE
    },
    customers: {
        index: (page = 1) => `${environment.backend.endpoint}/customers?page=${page}`, // GET
        create: () => `${environment.backend.endpoint}/customers`, // POST
        sites: (page = 1) => `${environment.backend.endpoint}/customers/sites?page=${page}`, // GET
        searchSites: (page = 1) => `${environment.backend.endpoint}/customers/sites/search?page=${page}`, // GET
        show: (customerId: string) => `${environment.backend.endpoint}/customers/${customerId}`, // GET
        search: (page = 1) => `${environment.backend.endpoint}/customers/search?page=${page}`, // POST
        edit: (customerId: string) => `${environment.backend.endpoint}/customers/${customerId}`, // PUT
        delete: (customerId: string) => `${environment.backend.endpoint}/customers/${customerId}`, // DELETE
        siteMaintenances: {
            list: (customerSiteId: string, ) => `${environment.backend.endpoint}/maintenances/show-by-customer-site/${customerSiteId}`, // GET
        },
        siteItems: {
            list: (customerId: string, siteId: string) => `${environment.backend.endpoint}/customers/${customerId}/site/${siteId}/items`, // GET
        },
        siteArchive: {
            list: (customerId: string, siteId: string) => `${environment.backend.endpoint}/customers/${customerId}/site/${siteId}/media`, // GET
            upload: (customerId: string, siteId: string) => `${environment.backend.endpoint}/customers/${customerId}/site/${siteId}/media`, // POST
            delete: (customerId: string, siteId: string, mediaId: string) => `${environment.backend.endpoint}/customers/${customerId}/site/${siteId}/media/${mediaId}`, // DELETE
            download: (customerId: string, siteId: string, mediaId: string) => `${environment.backend.endpoint}/customers/${customerId}/site/${siteId}/media/${mediaId}`, // POST
            show: (customerId: string, siteId: string, mediaId: string) => `${environment.backend.endpoint}/customers/${customerId}/site/${siteId}/media/${mediaId}/show`, // POST
        },
        contratto: {
            download: (customerId: string) => `${environment.backend.endpoint}/customers/${customerId}/contratto`, // GET
            upload: (customerId: string) => `${environment.backend.endpoint}/customers/${customerId}/contratto`, // POST
            delete: (customerId: string) => `${environment.backend.endpoint}/customers/${customerId}/contratto`, // DELETE
        }
    },
    maintenance: {
        getMaintenancesByMonth: (month: string) => `${environment.backend.endpoint}/maintenances/month-planning/${month}`, // GET
        createMaintenance: () => `${environment.backend.endpoint}/maintenances`, // POST
        getMaintenance: (maintenanceId: string) => `${environment.backend.endpoint}/maintenances/${maintenanceId}`, // GET
        getMaintenanceByUser: (userId?: string) => `${environment.backend.endpoint}/maintenances/dtl/${userId}`, // GET
        getMaintenances: () => `${environment.backend.endpoint}/maintenances/dtl`, // GET
        updateMaintenance: (maintenanceId: string) => `${environment.backend.endpoint}/maintenances/${maintenanceId}`, // PUT
        startMaintenance: (maintenanceId: number) => `${environment.backend.endpoint}/maintenances/start/${maintenanceId}`, // GET
        stopMaintenance: (maintenanceId: number) => `${environment.backend.endpoint}/maintenances/close/${maintenanceId}`, // GET
        getMine: () => `${environment.backend.endpoint}/maintenances/show-mine`, // GET
        sessions: {
            joinSession: (maintenanceId: number) => `${environment.backend.endpoint}/maintenances/technician-session/${maintenanceId}/join`, // GET
            leaveSession: (maintenanceId: number, sessionId: string) => `${environment.backend.endpoint}/maintenances/technician-session/${maintenanceId}/leave/${sessionId}`, // GET
            checklists: {
                sendReport: (maintenanceId: string, itemId: number, sessionId: string) => `${environment.backend.endpoint}/maintenances/${maintenanceId}/checklist/${itemId}/${sessionId}`,
            }
        },
        item: {
            getList: (maintenanceId: string) =>  `${environment.backend.endpoint}/maintenances/${maintenanceId}/show`,
        }
    },
    repository: {
        addItem: () => `${environment.backend.endpoint}/items`, // POST
        updateItem: (itemId: string) => `${environment.backend.endpoint}/items/${itemId}`, // PUT
        listByCategory: (categoryId: number, page = 1) => `${environment.backend.endpoint}/items/list/${categoryId}?page=${page}`, // PUT
        searchByCategory: (categoryId: number, page = 1) => `${environment.backend.endpoint}/items/search/${categoryId}?page=${page}`, // POST
        attachToCustomerSite: (itemId: string, customerSiteId: string) => `${environment.backend.endpoint}/items/${itemId}/attach/${customerSiteId}`, // POST
        detachToCustomerSite: (itemId: string, customerSiteId: string) => `${environment.backend.endpoint}/items/${itemId}/detach/${customerSiteId}`, // POST
    },
    qrcode: {
        status: (code: string) => `${environment.backend.endpoint}/qr/status/${code}`, // GET
        getPageList: (page = 1) => `${environment.backend.endpoint}/qr/pages/list?page=${page}`, // GET
        downloadPage: (pageId: string) => `${environment.backend.endpoint}/qr/pages/print/${pageId}`, // GET
        createPage: () => `${environment.backend.endpoint}/qr/pages/create`, // GET
    }
}
