import {Component} from '@angular/core';
import {WebappService} from "./shared/services/webapp.service";
import {MaintenanceStatus, MaintenanceStatusService} from "./shared/services/maintenance-status.service";

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent {
    /**
     * Constructor
     */
    constructor(
        private webAppService: WebappService,
        private _maintenanceStatus: MaintenanceStatusService,
    ) {
        window.addEventListener("beforeinstallprompt", (event) => {
            event.preventDefault();
            this.webAppService.setInstallPrompt(event)
        });
        this._maintenanceStatus.status = (localStorage.getItem('currentMaintenanceSession') && localStorage.getItem('currentMaintenanceSession') !== '0') ? MaintenanceStatus.active : MaintenanceStatus.inactive;
    }
}
